import React, { useEffect, useState } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax'

const RichText = ({ content, className }) => {
    const [mounted, setMounted] = useState(false);
    const config = {
        tex: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
        },
        options: {
            ignoreHtmlClass: 'w-e-text-container',
            processHtmlClass: 'rich-text',
        },
        startup: {
            typeset: true
        }
    };

    useEffect(() => {
        setMounted(true);
        
        // 手动触发 MathJax 渲染
        if (window.MathJax) {
            try {
                window.MathJax.typesetPromise && window.MathJax.typesetPromise();
            } catch (e) {
                console.error('MathJax typeset error:', e);
            }
        }
    }, [content]);

    return (
        <MathJaxContext config={config}>
            <MathJax hideUntilTypeset={'first'}>
                <div 
                    key={mounted ? 'mounted' : 'unmounted'}
                    className={`rich-text ${className || ''}`} 
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </MathJax>
        </MathJaxContext>
    );
};

export default RichText;